<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="6" md="6" class="pt-0">
        <v-img src="@/assets/error404.svg" alt="picture"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
